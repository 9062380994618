import React from "react";
import {
  Container,
  Grow,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Belongings: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Container sx={{ pt: 12 }} maxWidth="sm">
      <Grow in={true} style={{ transformOrigin: "0 0 0" }}>
        <div>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            defaultExpanded
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" component="h1">
                <strong>My Hosted GeTogether</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Content for My Hosted Get-Together Accordion
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>My Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Content for My Info Accordion</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>My Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Content for My Settings Accordion</Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grow>
    </Container>
  );
};

export default Belongings;
