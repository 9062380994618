import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Stack, CircularProgress, Container, Card, CardContent, Grow } from '@mui/material';
import OpenAI from 'openai';
import { MuiMarkdown } from 'mui-markdown';
import { CalendarEvent, createOrUpdateEvent } from '../api/calendar';
import { currentConfig as config } from '../config/config';
import { MessageContentImageFile, MessageContentText } from 'openai/resources/beta/threads/messages/messages';
import { Run } from 'openai/resources/beta/threads/runs/runs';
import { AuthUser } from 'aws-amplify/auth';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const ASSISTANT_ID = config.OPENAI_ASSISTANT_ID;

interface Message {
  role: 'user' | 'assistant';
  content: MessageContentText | MessageContentImageFile;
}

interface ChatPageProps {
  user: AuthUser | null;
}

export const ChatPage = (props: ChatPageProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [thread, setThread] = useState<any>();

  // Additional styles for message bubbles
  const messageStyles = {
    user: {
      backgroundColor: '#f0f0f0', // Light grey for user messages
      padding: '10px',
      borderRadius: '10px',
      maxWidth: '80%',
      marginLeft: 'auto',
      marginBottom: '10px',
    },
    assistant: {
      backgroundColor: '#d8d8d8', // Darker grey for assistant messages
      padding: '10px',
      borderRadius: '10px',
      maxWidth: '80%',
      marginRight: 'auto',
      marginBottom: '10px',
    }
  };

  useEffect(() => {
    const fetchAssistantAndThread = async () => {
      console.log('Fetching assistant and creating thread...');
      try {
        await openai.beta.assistants.retrieve(ASSISTANT_ID);
        setThread(await openai.beta.threads.create({}));
      } catch (error) {
        console.error('Error fetching assistant or creating thread:', error);
      }
    };
    fetchAssistantAndThread();
  }, []);



  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const sendMessage = async () => {
    if (!inputValue.trim()) return;

    setInputValue('');
    setIsLoading(true);

    let inputMessage: Message = {
      role: 'user',
      content: {
        type: 'text',
        text: {
          value: inputValue
        }
      } as MessageContentText
    };

    setMessages(messages => [...messages, inputMessage]);

    await openai.beta.threads.messages.create(
      thread.id,
      {
        role: "user",
        content: inputValue
      }
    );

    var run: Run = await openai.beta.threads.runs.create(
      thread.id,
      {
        assistant_id: ASSISTANT_ID
      }
    );

    await waitForRunCompletion(thread.id, run.id, props.user);

    const assistantMessageList = await openai.beta.threads.messages.list(thread.id);
    const assistantMessage = assistantMessageList.data[0].content[0];

    if (assistantMessage.type === 'text') {
      setMessages(messages => [...messages, {
        role: 'assistant',
        content: assistantMessage as MessageContentText
      }]);
    } else if (assistantMessage.type === 'image_file') {
      setMessages(messages => [...messages, {
        role: 'assistant',
        content: assistantMessage as MessageContentImageFile
      }]);
    }

    setIsLoading(false)
  };

  return (
    <Container sx={{ pt: 12 }} maxWidth="sm">
      <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: '600px', margin: 'auto', marginBottom: '32px' }}>
              <Typography variant="h5" component="h1" gutterBottom>
                <strong>Let's jazz up your GeTogether <br/> with  our Chat Assistant!</strong>
              </Typography>
              <Typography variant='body2'>
              We'll cover the what, when, where, what's-happening, and invites; then, voila, a snappy summary to seal the deal.
              </Typography>
              <Stack spacing={2}>
                {messages.map((message, index) => (
                  <Box key={index} sx={message.role === 'user' ? messageStyles.user : messageStyles.assistant}>
                    <Typography key={index} align={message.role === 'user' ? 'right' : 'left'} variant='body2'>
                      <MuiMarkdown>
                        {message.content.type === 'text'
                          ? message.content.text.value
                          : message.content.image_file.file_id}
                      </MuiMarkdown>
                    </Typography>
                  </Box>
                ))}
                {isLoading && (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                )}
              </Stack>
              <Box
                component="form"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="What event are you hosting?"
                  value={inputValue}
                  onChange={handleInputChange}
                  size='small'
                  multiline
                />
                <Button variant="contained" sx={{ backgroundImage: 'linear-gradient(135deg, rgba(253,246,31,1) 0%, rgba(254,213,188,1) 100%)', color: '#000', padding: '10px', marginLeft: '10px' }} onClick={sendMessage}>
                  <Typography variant='body2'>Enter</Typography>
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grow>
    </Container>
  );
};

const waitForRunCompletion = async (threadId: string, runId: string, user: AuthUser | null): Promise<Run> => {
  let runStatus = await openai.beta.threads.runs.retrieve(threadId, runId);

  if (runStatus.status === 'requires_action' && runStatus.required_action?.submit_tool_outputs.tool_calls[0]) {
    const apiInputJSON: CalendarEvent = JSON.parse(runStatus.required_action.submit_tool_outputs.tool_calls[0].function.arguments);
    const apiResponse = await createOrUpdateEvent({
      ...apiInputJSON,
      author: user?.signInDetails?.loginId || ''
    });

    runStatus = await openai.beta.threads.runs.submitToolOutputs(threadId, runId, {
      tool_outputs: [
        {
          tool_call_id: runStatus.required_action.submit_tool_outputs.tool_calls[0].id,
          output: JSON.stringify(apiResponse)
        }
      ]
    });
  }

  if (runStatus.status !== 'completed') {
    await new Promise(resolve => setTimeout(resolve, 5000));
    return waitForRunCompletion(threadId, runId, user);
  }

  return runStatus;
};



export default ChatPage;