const prodConfig = {
  CALENDAR_API_URL: 'https://5y0odak699.execute-api.us-east-1.amazonaws.com/prod',
  IS_CHAT_ENABLED: true,
  OPENAI_ASSISTANT_ID: 'asst_pJgl53zzKTchPGWtRBMhte5C',
  COGNITO: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_hXSrGIBhB',
    USER_POOL_WEB_CLIENT_ID: '630r8jb4is4du3omdmlgh3e6uo',
  }
}

const devConfig = {
  CALENDAR_API_URL: 'https://5y0odak699.execute-api.us-east-1.amazonaws.com/prod',
  IS_CHAT_ENABLED: true,
  OPENAI_ASSISTANT_ID: 'asst_pJgl53zzKTchPGWtRBMhte5C',
  COGNITO: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_hXSrGIBhB',
    USER_POOL_WEB_CLIENT_ID: '15t3mmkrls19do19n9aorug6ma',
  }
}

export const currentConfig = process.env.REACT_APP_STAGE === 'production' ? prodConfig : devConfig;