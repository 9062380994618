import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const OpenContactsButton: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button id='show all contacts' onClick={handleButtonClick} variant="contained" type="submit" sx={{ backgroundImage: 'linear-gradient(135deg, rgba(253,246,31,1) 0%, rgba(254,213,188,1) 100%)', color: '#000' }}>
        Invite your friends
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Instructions
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default OpenContactsButton;