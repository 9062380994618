import {
  signUp,
  confirmSignUp,
  signIn,
  signOut,
  autoSignIn,
  getCurrentUser,
  fetchAuthSession,
  ConfirmSignUpInput,
  SignInInput,
  AuthUser,
} from "aws-amplify/auth";

export type SignUpParameters = {
  username: string;
  password: string;
  email: string;
  phone_number: string;
};

export async function handleSignUp({
  username,
  password,
  email,
  phone_number,
}: SignUpParameters) {
  try {
    await signUp({
      username,
      password,
      options: {
        userAttributes: {
          email,
          phone_number, // E.164 number convention
        },
        // optional
        autoSignIn: true, // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
      },
    });
  } catch (error) {
    console.log("error signing up:", error);
  }
}

export async function handleSignUpConfirmation({
  username,
  confirmationCode,
}: ConfirmSignUpInput) {
  try {
    await confirmSignUp({
      username,
      confirmationCode,
    });
  } catch (error) {
    console.log("error confirming sign up", error);
  }
}

export async function handleAutoSignIn() {
  try {
    await autoSignIn();
    // handle sign-in steps
  } catch (error) {
    console.log(error);
  }
}

export async function handleSignIn({ username, password }: SignInInput) {
  try {
    await signIn({ username, password });
  } catch (error) {
    console.log("error signing in", error);
  }
}

export async function handleSignOut() {
  try {
    await signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

export async function currentAuthenticatedUser(): Promise<AuthUser> {
  return await getCurrentUser();
}

export async function currentSession() {
  try {
    return (await fetchAuthSession()).tokens ?? {};
  } catch (err) {
    console.log(err);
  }
}

/**
 * The following doc was followed to set up these functions.
 * https://docs.amplify.aws/javascript/build-a-backend/auth/enable-sign-up/
 *
 * This doc below will allow us to customize the pages
 * https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
 */
