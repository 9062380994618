import React from 'react';
import { CardHeader, Typography, Box, CardContent, Divider } from '@mui/material';

import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import TimelapseTwoToneIcon from '@mui/icons-material/TimelapseTwoTone';

type EventFormSummaryProps = {
  title: string;
  date: string;
  time: string;
  description: string;
  url?: string;
};

const EventFormSummary: React.FC<EventFormSummaryProps> = ({ title, date, time, description, url }) => {
  return (
    <>
      <Box>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', }} >
          <CardHeader
            tilte={title}
            titleTypographyProps={
              {
                fontWeight: 900,
              }
            }
          ></CardHeader>
          <Divider sx={{ mb: 2, mt: -2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <LocationOnTwoToneIcon color="primary" sx={{ marginRight: '8px' }} />
              <Typography variant="caption">
                {time}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <EventTwoToneIcon color="primary" sx={{ marginRight: '8px' }} />
              <Typography variant="caption">
                {date}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <EventTwoToneIcon color="primary" sx={{ marginRight: '8px' }} />
              <Typography variant="caption">
                {date}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <TimelapseTwoToneIcon color="primary" sx={{ marginRight: '8px' }} />
              <Typography variant="caption">
                {time}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1">
            {description}
          </Typography>

          <Typography variant="body1">
            {url && <p><strong>GeTogether Link:</strong> {url}</p>}
          </Typography>
        </CardContent>
      </Box>
    </>
  );
};

export default EventFormSummary;