import * as React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import RestoreIcon from '@mui/icons-material/Restore';
import AssistantIcon from '@mui/icons-material/Assistant';
import PersonIcon from '@mui/icons-material/Person';
import AddBoxIcon from '@mui/icons-material/AddBox';

const BottomNavigationBar: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isCurrentPath = (path: string) => currentPath === path;
  const getActionStyles = (path: string) => ({
    backgroundColor: isCurrentPath(path) ? '#FAFAFA' : '#FFF',
    color: isCurrentPath(path) ? '#000' : '#AFAFAF',
  });
  return (
    <BottomNavigation sx={{ borderRadius: '32px 32px 0 0' }} value={currentPath} showLabels>
      <BottomNavigationAction
        label="Feed"
        value="/feed"
        icon={<RestoreIcon />}
        sx={getActionStyles('/feed')}
        component={Link}
        to="/"
      />
      <BottomNavigationAction
        label="Add"
        value="/add"
        icon={<AddBoxIcon />}
        sx={getActionStyles('/add')}
        component={Link}
        to="/add-event"
      />
      <BottomNavigationAction
        label="Assistant"
        value="/friends"
        icon={<AssistantIcon />}
        sx={getActionStyles('/chat')}
        component={Link}
        to="/chat"
      />
      <BottomNavigationAction
        label="Belongings"
        value="/friends"
        icon={<PersonIcon />}
        sx={getActionStyles('/belongings')}
        component={Link}
        to="/belongings"
      />
    </BottomNavigation>
  );
};

export default BottomNavigationBar;