import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Typography, Box, Card, CardContent, CardMedia, Container, Grow,} from '@mui/material';
import EventFormSummary from './EventFormSummary';
import OpenContactsButton from './OpenContactsButton';
import { CalendarEvent, createOrUpdateEvent, downloadEventAsICS } from '../api/calendar';
import { CalendarInputEvent } from '../types/types';
import { transformEventToAPIFormat } from '../utils/helpers';
import { AuthUser } from 'aws-amplify/auth';

interface AddEventFormProps {
  user: AuthUser | null;
}

const AddEventForm: React.FC<AddEventFormProps> = (props: AddEventFormProps) => {
  const [title, setTitle] = useState<string>('');
  const [length, setLength] = useState<number>(3);
  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(true);

  const [calendarEvent, setCalendarEvent] = useState<CalendarEvent>();

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      alert('Please select a time');
    }
    setTime(event.target.value);
  };

  const handleLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLength(Number(event.target.value));
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const author = props.user;

    if (title === '' || date === '' || time === '') {
      alert('Please fill out all fields');
      return;
    }

    if (length === 0) {
      alert('Please select a length');
      return;
    }

    if (!author) {
      alert('Please log-in before creating a get together')
    }

    const calendarEvent: CalendarInputEvent = {
      title,
      date,
      time,
      length,
      description,
      author: author?.signInDetails?.loginId || ''
    }

    const formattedCalendarEvent: CalendarEvent = transformEventToAPIFormat(calendarEvent);

    downloadEventAsICS(formattedCalendarEvent)

    try {
      const apiResponse = await createOrUpdateEvent(formattedCalendarEvent);
      setCalendarEvent({
        ...formattedCalendarEvent,
        url: apiResponse || 'NA'
      });
    } catch (error) {
      console.error('An error occurred with the request', error)
    }

    setIsSaved(true);
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsSaved(false);
    setIsEditing(true);
  };

  return (
    <>
      <Container sx={{ pt: 12 }} maxWidth="sm">
        <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
          <form onSubmit={handleSave}>
            {isEditing && (
              <>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h1" ><strong>Add/Host a GeTogether</strong></Typography>
                    <Typography variant="body1">Populate these fields to whip up your epic GeTogether! Once you save the deets, the party-passes are yours to send out to your crew.</Typography>
                    <TextField
                      label="Title"
                      variant="outlined"
                      fullWidth
                      value={title}
                      onChange={handleTitleChange}
                      margin="normal"
                    />
                    <TextField
                      label="Date"
                      type="date"
                      variant="outlined"
                      fullWidth
                      value={date}
                      onChange={handleDateChange}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      label="Time"
                      type="time"
                      variant="outlined"
                      fullWidth
                      value={time}
                      onChange={handleTimeChange}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      label="How many hours?"
                      type="number"
                      inputProps={{ min: 1, max: 48 }}
                      variant="outlined"
                      fullWidth
                      value={length}
                      onChange={handleLengthChange}
                      margin="normal"
                    />
                    <TextField
                      label="Description"
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                      value={description}
                      onChange={handleDescriptionChange}
                      margin="normal"
                    />
                    <Button sx={{ backgroundImage: 'linear-gradient(45deg, rgba(253,246,31,1) 0%, rgba(254,213,188,1) 100%)', color: '#000', }} type="submit" variant="contained" color="primary">
                      Let the good times roll
                    </Button>
                  </CardContent>
                </Card>
              </>
            )}
          </form>
        </Grow>
      </Container>
      {isSaved && (
        <>
          <Box mt={-12}>
            <CardMedia
              component="img"
              height="275"
              width="100%"
              image='https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendy-wei-1190298.jpg&fm=jpg'
            ></CardMedia>
          </Box>
          <Box pt={4} mb={-2} bgcolor="#FFF">
            <Container maxWidth="sm">
              <CardContent>
                <Typography variant="h5" component="h1"><strong>HOORAY!</strong></Typography>
                <Typography variant="body1">Time to summon your squad and make it legendary – hit those invites like you're spreading the vibes! </Typography>
              </CardContent>
              <EventFormSummary title={title} date={date} time={time} description={description} url={calendarEvent?.url} />
              <CardContent>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", gap: 4, mb: 8 }}>
                <OpenContactsButton />
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", gap: 4, mb: 8 }}>
                  <Button onClick={handleEdit}>Edit</Button>
                  <Button variant='text' color='error'>Delete</Button>
                </Box>
              </Box>
              </CardContent>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default AddEventForm;