import { currentConfig as config } from "../config/config";
import ical from 'ical-generator';
import { v4 as uuid } from 'uuid';

const CALENDAR_API_URL = config.CALENDAR_API_URL;

export interface CalendarEvent {
  id?: string;
  summary: string;
  description?: string;
  start: string;
  end: string;
  location?: string;
  url: string;
  author: string
}

export async function createOrUpdateEvent(event: CalendarEvent): Promise<string> {
  console.log('Creating event...', event)
  try {
    const response = await fetch(`${CALENDAR_API_URL}/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event),
    });

    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.message || 'Error creating or updating event.');
    }

    const eventData = await response.text();

    return eventData;
  } catch (error) {
    throw error;
  }
}

export async function getEvent(id: string): Promise<CalendarEvent> {
  try {
    const response = await fetch(`${CALENDAR_API_URL}/getEvent/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.message || 'Error fetching the event.');
    }

    const eventData: CalendarEvent = await response.json();
    return eventData;
  } catch (error) {
    throw error;
  }
}

function convertToICS(event: CalendarEvent): string {
  const calendar = ical({ name: 'gettogether.com' });
  const eventId = event.id || uuid() //Not sure if this even works
  calendar.createEvent({
    id: eventId,
    start: new Date(event.start),
    end: new Date(event.end),
    summary: event.summary,
    description: event.description,
    location: event.location,
    timezone: 'local'
  });

  const icsData = calendar.toString();

  return icsData;
}

export function downloadEventAsICS(event: CalendarEvent): void {
  const icsData = convertToICS(event);

  const fileName = `${event.summary}.ics`;

  console.log("icsData", icsData)
  const blob = new Blob([icsData], { type: "text/calendar" });
  const url = URL.createObjectURL(blob);
  console.log(url)
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
}