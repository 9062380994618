import { CalendarEvent } from "../api/calendar";
import { CalendarInputEvent } from "../types/types";

const USE_UTC = false;

export function transformEventToAPIFormat(event: CalendarInputEvent): CalendarEvent {
  const startDate = new Date(`${event.date}T${event.time}:00.000${USE_UTC ? 'Z' : ''}`);
  const endDate = new Date(startDate);
  endDate.setHours(startDate.getHours() + event.length);

  return {
    summary: event.title,
    description: event.description,
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    location: "Remote",
    url: '',
    author: event.author
  };
}