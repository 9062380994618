import * as React from "react";
import { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Avatar, Paper } from "@mui/material";
import logo from "../src/images/get-togther-mock-logo.png";
import EventFeed from "./components/EventFeed";
import AddEventForm from "./components/AddEventForm";
import BottomNavigationBar from "./components/BottomNavigationBar"; // Import your BottomNavigationBar component
import ChatPage from "./components/ChatPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Belongings from "./components/Belongings";
import Watermark from "./images/Watermark_GTM.png";
import { Amplify } from "aws-amplify";
import { Hub } from "@aws-amplify/core";
import { currentConfig as config } from "./config/config";
import { currentAuthenticatedUser } from "./auth/auth";
import { AuthUser } from "aws-amplify/auth";
import { Authenticator } from "@aws-amplify/ui-react";

const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: config.COGNITO.USER_POOL_ID,
      userPoolClientId: config.COGNITO.USER_POOL_WEB_CLIENT_ID,
      signUpVerificationMethod: "code",
    },
  },
};

//@ts-ignore
Amplify.configure(awsConfig);

const settings = ["Profile", "Account", "Dashboard", "Logout"];

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#202020",
      light: "#737373",
      dark: "#000000",
    },
    secondary: {
      main: "#ffeb3b",
    },
    background: {
      paper: "#ffffff",
      default: "#f1f1f1",
    },
    error: {
      main: "#b3351c",
    },
    warning: {
      main: "#ffd600",
      contrastText: "rgba(0,0,0,0.87)",
    },
    info: {
      main: "#607d8b",
    },
    success: {
      main: "#7cb342",
    },
    divider: "rgba(0,0,0,0.12)",
  },
  typography: {
    fontFamily: "Raleway",
    fontWeightBold: 800,
    htmlFontSize: 16,
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 400,
    },
    body1: {
      fontFamily: "Open Sans",
    },
    subtitle2: {
      fontFamily: "Open Sans",
    },
    subtitle1: {
      fontFamily: "Open Sans",
    },
    body2: {
      fontFamily: "Open Sans",
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
});

function App() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [user, setUser] = React.useState<AuthUser | null>(null);

  useEffect(() => {
    currentAuthenticatedUser()
      .then((user) => {
        console.log("User logged in as:", user);
        setUser(user);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // Subscribe to authentication events using Hub
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signedIn") {
        setUser(payload.data);
        window.location.reload();
      }
      if (payload.event === "signedOut") {
        setUser(null);
        window.location.reload();
      }
    });
  }, [setUser]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Authenticator loginMechanisms={["username", "email"]}>
        <Box
          minHeight="100vh"
          overflow="auto"
          sx={{
            backgroundColor: "#b3b3b343",
            backgroundImage: `url(${Watermark})`,
            backgroundPositionY: "bottom",
            backgroundRepeat: "no-repeat",
          }}
        >
          <AppBar
            component="nav"
            position="fixed"
            sx={{
              backgroundImage:
                "linear-gradient(135deg, rgba(253,246,31,1) 0%, rgba(254,213,188,1) 100%)",
            }}
          >
            <Container maxWidth="xl">
              <Toolbar sx={{ justifyContent: "space-between" }} disableGutters>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <img
                    src={logo}
                    alt="GeTogether Logo"
                    width="48px"
                    height="auto"
                  ></img>
                  <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                      ml: 1,
                      fontWeight: 700,
                      letterSpacing: "-.025rem",
                      color: "#000",
                      textDecoration: "none",
                    }}
                  >
                    GeTogether
                  </Typography>
                </Box>
                <Box>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        alt={user?.signInDetails?.loginId || "Remy Sharp"}
                        src="/static/images/avatar/2.jpg"
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem key={setting} onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
          <Router>
            <Routes>
              <Route path="/" element={<EventFeed />} />
              <Route path="/add-event" element={<AddEventForm user={user} />} />
              <Route path="/chat" element={<ChatPage user={user} />} />
              <Route path="/belongings" element={<Belongings />} />
            </Routes>
            <Paper
              elevation={4}
              component="div"
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: "32px 32px 0 0",
              }}
            >
              <BottomNavigationBar />
            </Paper>
          </Router>
        </Box>
      </Authenticator>
    </ThemeProvider>
  );
}

export default App;
//export default App;
